<template>
  <main class="container mx-auto flex flex-col items-center py-10 px-4">
    <h1 class="text-2xl font-bold mb-10">{{ date }} の自習</h1>
    <div class="flex flex-col items-start w-full">
      <section
        v-if="!existReflection"
        class="flex items-center p-3 mb-6 rounded-md bg-white text-yellow-300"
      >
        <m-icon type="warning" size="6" class="mr-2" />
        <span class="text-sm font-medium text-gray-500">
          この学習に対する振り返りは終わっていません
        </span>
      </section>
      <section class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">学習時間: {{ learningTimeText }}</h3>
        <ul
          v-if="sortedRecords.length > 0"
          class="w-full p-3 rounded-md bg-white"
        >
          <li
            v-for="record in sortedRecords"
            :key="record.start"
            class="text-gray-700 text-sm"
          >
            • {{ timeTextOf(record) }}
          </li>
        </ul>
      </section>
      <section class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">学習科目</h3>
        <p class="w-full p-3 rounded-md bg-white text-sm">{{ subjects }}</p>
      </section>
      <section class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">目標</h3>
        <p class="w-full p-3 rounded-md bg-white text-sm">{{ goal }}</p>
      </section>
      <section v-if="existReflection" class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">
          目標の達成度:
          <span class="text-secondary-500">{{ goalAchievement }}%</span>
        </h3>
        <div class="w-full rounded-full bg-gray-200">
          <div
            class="h-2 rounded-full bg-secondary-500"
            :class="achievementCss"
          />
        </div>
      </section>
      <section v-if="existReflection" class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">目標以外にできたこと</h3>
        <p class="w-full p-3 rounded-md bg-white text-gray-700 text-sm">
          {{ additionalContent }}
        </p>
      </section>
      <section v-if="existReflection" class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">成果物</h3>
        <div
          v-if="images.length > 0"
          class="flex w-full p-3 rounded-md bg-white overflow-x-scroll"
        >
          <a
            v-for="image in images"
            :key="image"
            :href="image"
            target="_blank"
            rel="noopener noreferrer"
            class="flex-none relative mr-4"
          >
            <img :src="image" alt="成果物" class="h-60 w-auto" />
            <div
              class="absolute w-full h-full top-0 left-0 hover:bg-opacity-50 hover:bg-gray-100"
            ></div>
          </a>
        </div>
        <p v-else class="w-full p-3 rounded-md bg-white text-gray-700 text-sm">
          -
        </p>
      </section>
      <section v-if="existReflection" class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">感想</h3>
        <p class="w-full p-3 rounded-md bg-white text-gray-700 text-sm">
          {{ thoughts }}
        </p>
      </section>
      <section v-if="existReflection" class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">困ったこと・連絡事項・その他</h3>
        <p class="w-full p-3 rounded-md bg-white text-gray-700 text-sm">
          {{ other }}
        </p>
      </section>
    </div>
  </main>
</template>

<script lang="ts">
import MIcon from "@/components/MIcon.vue";
import { saveErrorLog } from "@/api/error";
import { getLearning } from "@/api/learning";
import {
  Learning,
  learningCollectionKey,
  LearningRecord,
  getStudyingSecondsByRecord
} from "@/entities/learning";
import store, { awaitStudentLoaded } from "@/store";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  }
})
export default class LearningDetail extends Vue {
  learning: Learning | null = null;

  get existReflection(): boolean {
    return !!this.learning?.data.reflection;
  }

  get date(): string {
    if (!this.learning) {
      return "";
    }
    const timeRecords = [...this.learning.data.records];
    timeRecords.sort((a, b) => a.end - b.end);
    const startTime = dayjs(timeRecords[0].start * 1000).locale("ja");
    return startTime.format("M/D");
  }

  get sortedRecords(): LearningRecord[] {
    if (!this.learning) {
      return [];
    }
    const timeRecords = [...this.learning.data.records];
    timeRecords.sort((a, b) => a.end - b.end);
    return timeRecords;
  }

  get learningTimeText(): string {
    if (this.sortedRecords.length === 0) {
      return "0 時間 0 分";
    }
    const studyingMinutes = Math.floor(
      getStudyingSecondsByRecord(this.sortedRecords) / 60
    );
    return `${Math.floor(studyingMinutes / 60)} 時間 ${
      studyingMinutes % 60
    } 分`;
  }

  get subjects(): string {
    if (!this.learning?.data.subjects) return "";
    if (this.learning.data.subjects.length === 1) {
      return this.learning.data.subjects[0].name;
    }
    const names =
      this.learning?.data.subjects.map(subject => subject.name).join(" : ") ??
      "-";
    const ratios =
      this.learning?.data.subjects.map(subject => subject.ratio).join(" : ") ??
      "-";
    return `${names} = ${ratios}`;
  }

  get goal(): string {
    return this.learning?.data.goal ?? "";
  }

  get goalAchievement(): number {
    return this.learning?.data.reflection?.achievementRate ?? 0;
  }

  get achievementCss() {
    if (!this.learning || !this.learning.data.reflection) {
      return { "w-0": true };
    }
    const achievement = this.learning.data.reflection.achievementRate;
    if (achievement <= 0) {
      return { "w-0": true };
    } else if (achievement <= 8) {
      return { "w-1/12": true };
    } else if (achievement <= 17) {
      return { "w-1/6": true };
    } else if (achievement <= 25) {
      return { "w-1/4": true };
    } else if (achievement <= 33) {
      return { "w-1/3": true };
    } else if (achievement <= 42) {
      return { "w-5/12": true };
    } else if (achievement <= 50) {
      return { "w-1/2": true };
    } else if (achievement <= 58) {
      return { "w-7/12": true };
    } else if (achievement <= 67) {
      return { "w-2/3": true };
    } else if (achievement <= 75) {
      return { "w-3/4": true };
    } else if (achievement <= 83) {
      return { "w-5/6": true };
    } else if (achievement <= 99) {
      return { "w-11/12": true };
    } else {
      return { "w-full": true };
    }
  }

  get additionalContent(): string {
    return this.learning?.data.reflection?.additionalContent ?? "-";
  }

  get images(): string[] {
    return this.learning?.data.reflection?.images ?? [];
  }

  get thoughts(): string {
    return this.learning?.data.reflection?.thoughts ?? "-";
  }

  get other(): string {
    return this.learning?.data.reflection?.other ?? "-";
  }

  timeTextOf(record: LearningRecord): string {
    const startTime = dayjs(record.start * 1000).locale("ja");
    const endTime = dayjs(record.end * 1000).locale("ja");
    return startTime.format("HH:mm") + " 〜 " + endTime.format("HH:mm");
  }

  async created() {
    const student = await awaitStudentLoaded(store);

    const learningId = this.$route.params.learningId as string;
    if (!learningId) {
      return;
    }

    try {
      const learning = await getLearning(
        student.ref.collection(learningCollectionKey).doc(learningId)
      );
      if (!learning) {
        alert("学習データが見つかりませんでした");
        this.$router.replace("/");
        return;
      }
      this.learning = learning;
    } catch (e) {
      alert(`学習データの取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to get learning data"
      );
    }
  }
}
</script>
