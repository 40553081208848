
import MIcon from "@/components/MIcon.vue";
import { saveErrorLog } from "@/api/error";
import { getLearning } from "@/api/learning";
import {
  Learning,
  learningCollectionKey,
  LearningRecord,
  getStudyingSecondsByRecord
} from "@/entities/learning";
import store, { awaitStudentLoaded } from "@/store";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  }
})
export default class LearningDetail extends Vue {
  learning: Learning | null = null;

  get existReflection(): boolean {
    return !!this.learning?.data.reflection;
  }

  get date(): string {
    if (!this.learning) {
      return "";
    }
    const timeRecords = [...this.learning.data.records];
    timeRecords.sort((a, b) => a.end - b.end);
    const startTime = dayjs(timeRecords[0].start * 1000).locale("ja");
    return startTime.format("M/D");
  }

  get sortedRecords(): LearningRecord[] {
    if (!this.learning) {
      return [];
    }
    const timeRecords = [...this.learning.data.records];
    timeRecords.sort((a, b) => a.end - b.end);
    return timeRecords;
  }

  get learningTimeText(): string {
    if (this.sortedRecords.length === 0) {
      return "0 時間 0 分";
    }
    const studyingMinutes = Math.floor(
      getStudyingSecondsByRecord(this.sortedRecords) / 60
    );
    return `${Math.floor(studyingMinutes / 60)} 時間 ${
      studyingMinutes % 60
    } 分`;
  }

  get subjects(): string {
    if (!this.learning?.data.subjects) return "";
    if (this.learning.data.subjects.length === 1) {
      return this.learning.data.subjects[0].name;
    }
    const names =
      this.learning?.data.subjects.map(subject => subject.name).join(" : ") ??
      "-";
    const ratios =
      this.learning?.data.subjects.map(subject => subject.ratio).join(" : ") ??
      "-";
    return `${names} = ${ratios}`;
  }

  get goal(): string {
    return this.learning?.data.goal ?? "";
  }

  get goalAchievement(): number {
    return this.learning?.data.reflection?.achievementRate ?? 0;
  }

  get achievementCss() {
    if (!this.learning || !this.learning.data.reflection) {
      return { "w-0": true };
    }
    const achievement = this.learning.data.reflection.achievementRate;
    if (achievement <= 0) {
      return { "w-0": true };
    } else if (achievement <= 8) {
      return { "w-1/12": true };
    } else if (achievement <= 17) {
      return { "w-1/6": true };
    } else if (achievement <= 25) {
      return { "w-1/4": true };
    } else if (achievement <= 33) {
      return { "w-1/3": true };
    } else if (achievement <= 42) {
      return { "w-5/12": true };
    } else if (achievement <= 50) {
      return { "w-1/2": true };
    } else if (achievement <= 58) {
      return { "w-7/12": true };
    } else if (achievement <= 67) {
      return { "w-2/3": true };
    } else if (achievement <= 75) {
      return { "w-3/4": true };
    } else if (achievement <= 83) {
      return { "w-5/6": true };
    } else if (achievement <= 99) {
      return { "w-11/12": true };
    } else {
      return { "w-full": true };
    }
  }

  get additionalContent(): string {
    return this.learning?.data.reflection?.additionalContent ?? "-";
  }

  get images(): string[] {
    return this.learning?.data.reflection?.images ?? [];
  }

  get thoughts(): string {
    return this.learning?.data.reflection?.thoughts ?? "-";
  }

  get other(): string {
    return this.learning?.data.reflection?.other ?? "-";
  }

  timeTextOf(record: LearningRecord): string {
    const startTime = dayjs(record.start * 1000).locale("ja");
    const endTime = dayjs(record.end * 1000).locale("ja");
    return startTime.format("HH:mm") + " 〜 " + endTime.format("HH:mm");
  }

  async created() {
    const student = await awaitStudentLoaded(store);

    const learningId = this.$route.params.learningId as string;
    if (!learningId) {
      return;
    }

    try {
      const learning = await getLearning(
        student.ref.collection(learningCollectionKey).doc(learningId)
      );
      if (!learning) {
        alert("学習データが見つかりませんでした");
        this.$router.replace("/");
        return;
      }
      this.learning = learning;
    } catch (e) {
      alert(`学習データの取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to get learning data"
      );
    }
  }
}
